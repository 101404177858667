<template>
<div class="my-4">
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loading && permissions.read">
      <b-col>
        <b-card class="mb-3">
          <b-form-group horizontal class="mt-4">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Type to Search" />
                <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
            </b-input-group>
          </b-form-group>
          <b-form inline>
            <span class="mr-2"><strong>Showing {{rowsShow.length}} out of {{rows.length}}</strong></span>
            <b-button class="mr-2" v-if="permissions.add" variant="outline-primary" :to="{ name: 'FormNew', params: {model: 'coverage' }}">new</b-button>
          </b-form>
        </b-card>
        <div>
          <b-card>
            <div class="mb-3" v-for="row in rowsShow" :key="row.id">
              <line-item
                :item="row"
                :username="user.username"
              />
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'

import LineItem from '@/components/CoverageSimple.vue'

export default {
  name: 'Coverage',
  components: {
    LineItem
  },
  computed: {
    rowsShow: function () {
      let rows = []
      rows = this.rows
      if (this.filter.length > 0) {
        rows = rows.filter(x => {
          const term = x.id + ' ' + x.name
          return term.toUpperCase().match(this.filter.toUpperCase())
        })
      }
      return rows
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'coverage', action: 'open coverage' })
    this.permissions.read = ac.can(this.user.acgroups).readAny('coverage').granted
    this.permissions.add = ac.can(this.user.acgroups).createAny('coverage').granted
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    const data = {
      permissions: {
        add: false,
        read: false
      },
      filter: '',
      loading: true,
      rows: []
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.rows = await this.$Amplify.API.get('cosmos', '/standard/coverage')
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
